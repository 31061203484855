import Box from "@mui/material/Box";
import Header from "src/components/App/PageHeader";
import MinimumPriceForm from "./MinimumPriceForm";
import { useEffect, useCallback, useState } from "react";
import { partnersService } from "src/api/services/partners";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { IPartner } from "../../../../api/types/partner";
import { minimumPriceService } from "../../../../api/services/minimumPrice";
import { IMinimumPrice } from "../../../../api/types/minimum-price";
import { ProgressSpinner } from "primereact/progressspinner";

const PriceManagement: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [minimumPrice, setMinimumPrice] = useState<IMinimumPrice>();

  const fetchCategories = useCallback(async () => {
    try {
      setLoading(true);
      const result = await minimumPriceService.get();
      console.log("result", result);
      setMinimumPrice(result.data.payload);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <>
      <Box sx={{ width: "100%", mb: 6 }}>
        <Header hasGoBack={true} title="Medium Price Limit" />
      </Box>
      {loading ? (
        <div className="flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : minimumPrice ? (
        <MinimumPriceForm minimumPrice={minimumPrice} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PriceManagement;
